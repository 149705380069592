<template>
  <div class="bg">
    <div class="info" v-if="flag">
      <div class="item">序号：{{userInfo.number}}</div>
      <div class="item">账号：{{userInfo.userName}}</div>
      <div class="item">密码：{{userInfo.userPasswd}}</div>
      <div class="item">网址：{{userInfo.url}}</div>
    </div>
    <div class="info info-error" v-else>
      <div class="error">{{errorMessage}}</div>
    </div>
  </div>
</template>

<script>
import { getCardInfo } from "@/api/user";
export default {
  data() {
    return {
      userInfo: {
        number: "",
        url: "",
        userName: "",
        userPasswd: ""
      },
      flag: false,
      errorMessage: ""
    };
  },
  created() {
    this.handleGetCardInfo();
  },
  methods: {
    async handleGetCardInfo() {
      let cardNo = this.$route.query.cardNo;
      let { data } = await getCardInfo({ cardNo });
      if (data.resultCode == "200") {
        this.userInfo = data.resultBody;
        this.flag = true;
      } else {
        this.errorMessage = data.resultBody;
        this.flag = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  width: 375px;
  height: 957px;
  background: url("../static/img/bg.png") no-repeat;
  background-size: cover;
  position: relative;
  .info {
    height: 115px;
    width: 310px;
    position: absolute;
    top: 124px;
    left: 50%;
    margin-left: -155px;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .item {
      font-size: 14px;
      text-align: left;
      color: #513115;
      font-weight: bold;
    }
    .error {
      font-size: 24px;
      text-align: center;
      color: #513115;
    }
  }
  .info-error {
    align-items: center;
    justify-content: center;
  }
}
</style>