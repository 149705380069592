// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/img/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".bg[data-v-59fdd89f]{width:10rem;height:25.52rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:cover;position:relative}.bg .info[data-v-59fdd89f]{height:3.06667rem;width:8.26667rem;position:absolute;top:3.30667rem;left:50%;margin-left:-4.13333rem;padding:.26667rem .53333rem;box-sizing:border-box;display:flex;flex-direction:column;justify-content:space-between}.bg .info .item[data-v-59fdd89f]{font-size:.37333rem;text-align:left;color:#513115;font-weight:700}.bg .info .error[data-v-59fdd89f]{font-size:.64rem;text-align:center;color:#513115}.bg .info-error[data-v-59fdd89f]{align-items:center;justify-content:center}", ""]);
// Exports
module.exports = exports;
